import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import LoginView from '../views/LoginView.vue'
// import IndexView from '../views/IndexView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/IndexView.vue'),
    meta: {
      // 设置页面标题
      title: '校易行运营平台',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    meta: {
      // 设置页面标题
      title: '登录',
    }
  },
  {
    path: '/outerIndex',
    name: 'outerIndex',
    component: () => import(/* webpackChunkName: "about" */ '../views/outerIndexView.vue'),
    meta: {
      // 设置页面标题
      title: '校易行运营平台',
    }
  },
  {
    path: '/outerLogin',
    name: 'outerLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/outerLoginView.vue'),
    meta: {
      // 设置页面标题
      title: '登录',
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {                // 判断是否有标题
    document.title = to.meta.title
  } else {                              // 无标题则默认显示
    document.title = '校易行运营平台';
  }
  await next();   // 最后执行下一步操作
})

export default router
